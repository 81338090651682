<template>
  <div
    class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
  >
    <div>
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="row row-cols-2 row-cols-md-4">
          <div
            class="col d-flex justify-content-center pt-3 p-3"
            v-for="item in data"
            :key="item.id"
          >
            <a
              :href="item.url"
              class="tw-transform tw-no-underline tw-transition-transform hover:-tw-translate-y-5 tw-duration-500"
            >
              <div
                class="tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <div class="tw-flex tw-justify-center">
                  <img
                    class="urlimage tw-text-white"
                    :src="baseUrl + item.image"
                  />
                </div>
                <h1
                  class="tw-text-sm tw-text-center tw-text-white"
                  style="width: 100px"
                >
                  {{ item.name }}
                </h1>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroLinks from "./HeroLinks.vue";
import axios from "axios";
export default {
  components: {
    HeroLinks,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_URL,
      data: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "featuredurls")
        .then((response) => {
          this.data = response.data;
          this.load = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.load = false;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.urlimage {
  height: 60px;
  width: 60px;
}
</style>
