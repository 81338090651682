export default [
  {
    path: "/",
    name: "Home",
    component: () => import("./pages/index"),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("./pages/contactUs"),
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: () => import("./pages/downloads"),
  },
  {
    path: "/post-details",
    name: "PostDetails",
    component: () => import("./pages/postDetails"),
  },

]
