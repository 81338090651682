var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container px-4",attrs:{"id":"custom-cards"}},[_c('h2',{staticClass:"pb-2 border-bottom"},[_vm._v("Latest Updates")]),_c('div',{staticClass:"row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5"},_vm._l((_vm.posts),function(item){return _c('div',{key:item.id,staticClass:"col"},[(item.media[0])?_c('div',{staticClass:"card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg",style:({
          backgroundImage:
            'linear-gradient(rgba(0, 0, 0, 0), rgba(197, 145, 63, 0.4)), url(' +
            item.media[0].original_url +
            ')',
          backgroundSize: 'cover',
        }),attrs:{"id":"show_bg_2"}},[_c('router-link',{attrs:{"to":{ path: 'post-details', query: { id: item.id } },"tag":"div"}},[_c('div',{staticClass:"d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1",attrs:{"routerLink":"['posts']"}},[_c('h2',{staticClass:"pt-5 mt-5 mb-4 display-6 lh-1 fw-bold"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('ul',{staticClass:"d-flex list-unstyled mt-auto"},[_c('li',{staticClass:"d-flex align-items-center details"},[_c('small',[_vm._v(_vm._s(item.post_type.type))])]),_c('li',{staticClass:"d-flex align-items-center"},[_c('svg',{staticClass:"bi me-2",attrs:{"width":"1em","height":"1em"}},[_c('use',{attrs:{"xlink:href":"#calendar3"}})]),_c('small',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))])])])])])],1):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }