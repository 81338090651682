<template lang="">
  <div>
    <footer class="container-fluid footer_background">
      <div class="container py-4">
        <div class="row justify-center">
          <div class="col-md-3" style="padding-top: 10px; margin-bottom: -40px">
            <div class="row">
              <div class="logo-part d-flex justify-center">
                <img
                  src="../../../assets/white_logo.png"
                  class="w-50 logo-footer"
                />
              </div>
            </div>
          </div>
          <div class="">
            <!-- <h5 class="col_heading">Get in touch</h5> -->
            <div class="d-flex align-items-center justify-center">
              <img
                src="../../../assets/footer/phone-solid.svg"
                width="30px"
                height="30px"
                alt=""
              />
              <a href="tel:+960 330 6969" class="btn-footer"> +960 330 6969 </a
              ><br />
            </div>
            <div class="d-flex align-items-center justify-center">
              <img
                src="../../../assets/footer/location-dot-solid.svg"
                width="30px"
                height="30px"
                alt=""
              />
              <a
                href="https://www.google.com/maps/place/Regional+Airports+Company+Limited/@4.1784304,73.5130241,21z/data=!4m12!1m6!3m5!1s0x3b3f7f89c939fee7:0x2801c566bef67261!2sRegional+Airports+Company+Limited!8m2!3d4.1784689!4d73.5130267!3m4!1s0x3b3f7f89c939fee7:0x2801c566bef67261!8m2!3d4.1784689!4d73.5130267?hl=en-GB"
                class="btn-footer px-2"
              >
                H. Suez 6th Floor, Ameeru Ahmed Magu, Male’ </a
              ><br />
            </div>
            <div class="d-flex align-items-center justify-center">
              <img
                src="../../../assets/footer/envelope-solid.svg"
                width="30px"
                height="30px"
                alt=""
              />
              <a
                href="mailto:info@airports.mv"
                class="btn-footer px-2 justify-center"
              >
                info@airports.mv </a
              ><br />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </footer>
    <div class="bg-light footer_strip">
      <div class="pattern-img p-relative left">
        <img
          src="../../../assets/home/icons/navpattern.png"
          alt=""
          class="pattern pattern-left"
        />
      </div>
      <b-container class="d-flex align-items-center justify-center py-3">
        <div>
          <h6>
            Regional Airports Company Limited. © All Rights Reserved 2022.
          </h6>
        </div>
        <div class="social d-flex align-items-center justify-content-between">
          <ul>
            <li>
              <a href="https://www.facebook.com/airportsmv/">
                <img
                  class="footer_icon"
                  src="../../../assets/home/icons/facebook-brands.svg"
                  width="20px"
                  height="20px"
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/airportsmv/">
                <img
                  src="../../../assets/home/icons/instagram-brands.svg"
                  width="20px"
                  height="20px"
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/airportsmv/">
                <img
                  src="../../../assets/home/icons/twitter-brands.svg"
                  width="20px"
                  height="20px"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </b-container>
      <div class="pattern-img p-relative left">
        <img
          src="../../../assets/home/icons/navpattern.png"
          alt=""
          class="pattern pattern-left"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
@import "../styles/footer.css";
.justify-center {
  justify-content: center !important;
}
.btn-footer {
  width: unset !important;
}
.row > * {
  /* width: fit-content; */
}
.col_heading {
  text-align: center;
}
.bg-light.footer_strip h6 {
  font-size: 12px;
  margin-bottom: 0;
}
.bg-light.footer_strip ul {
  margin-bottom: 0;
  padding-left: 0;
}
.bg-light.footer_strip .social ul li a img {
  width: 18px;
}
.bg-light.footer_strip .d-flex {
  flex-flow: column;
  gap: 5px;
}
.footer_background .btn-footer {
  font-size: 12px;
  line-height: 22px;
}
.footer_background img,
.footer_background svg {
  vertical-align: middle;
  width: 16px;
}
.footer_background .col_heading {
  margin-bottom: 20px;
}
</style>
