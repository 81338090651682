import Vue from "vue";
import App from "./App.vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import router from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { library } from "@fortawesome/fontawesome-svg-core";

Vue.use(require("vue-moment"));
/* import specific icons */

import "./assets/tailwind.css";

import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { Plugin } from "vue-fragment";
Vue.use(Plugin);

// /* add icons to the library */
library.add(faUserSecret);
library.add(faBook);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,

  render: (h) => h(App),
}).$mount("#app");

//For navigation shown on mobile
let navBtn = document.querySelector(".navbar-toggler");
navBtn.addEventListener("click", function () {
  navBtn.parentElement.classList.toggle("show-nav");
});

// For Close the nav by clicking anywhere in the body
let ignoreClickOnMeElement = document.querySelector(".navbar-toggler");
document.addEventListener("click", function (event) {
  let isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
  if (!isClickInsideElement) {
    navBtn.parentElement.classList.remove("show-nav");
  }
});
import moment from "moment";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).fromNow();
  }
});
