<template>
  <div>
    <!-- Hero -->
    <div class="tw-relative tw-h-screen tw-w-screen flex">
      <hero-links class="tw-z-50" />
      <Slider />
    </div>
    <!-- Hero Section -->
    <section class="sec_3">
      <div class="px-4 py-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          src="@/assets/logo.png"
          alt=""
          width="72"
          height="57"
        />
        <h1 class="display-4 fw-bold">What is Airports?</h1>
        <div class="col-lg-8 mx-auto">
          <p class="lead mb-4">
            Airports is our brand. We want to be established as the most
            competent Airport management company in Maldives, with future
            resolutions of managing more airports than most management companies
            in Maldives. We see a bright future of development not just locally
            but for the economy as a whole due to the companies achievements..
          </p>
          <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <router-link class="details rounded" :to="{ path: '/our-story' }">
              Our Story
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- Posts Section -->
    <section class="sec_2">
      <b-container class="pb-5">
        <div class="py-5 text-center second_heading">
          <h1 class="py-1 fw-bold">Catch up With Us!</h1>
          <p class="text-muted"></p>
        </div>
        <b-row class="d-flex align-items-center">
          <Posts />
        </b-row>
      </b-container>
    </section>

    <!-- Gallary Section -->
    <section class="sec_3">
      <b-container>
        <Gallary />
      </b-container>
    </section>

    <!-- Fourth Section - Daily Services Destination -->
    <section class="sec_4">
      <b-container>
        <div class="py-5 text-center">
          <h1 class="py-1 fw-bold">Our Airports</h1>
          <p class="text-muted"></p>
        </div>
        <div class="map">
          <Map />
        </div>
        <div class="mobilemap">
          <MobileMap />
        </div>
      </b-container>
    </section>

    <!-- Blank -->
    <!-- <div class="blank_sec_5 bh-white py-5"></div> -->
  </div>
</template>

<script>
import Slider from "../../../components/Hero/Slider.vue";
import CardImage from "../components/imageCard.vue";
import Map from "../components/Map.vue";
import MobileMap from "../components/MobileMap.vue";
import Posts from "../components/Posts.vue";
import ReusableCarousel from "../components/ReusableCarousel.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import HeroLinks from "../../../components/Hero/HeroLinks.vue";
import Gallary from "../components/Gallary.vue";
// import AppVue from "../../../App.vue";
export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      posts: [],
    };
  },
  components: {
    Slider,
    CardImage,
    Carousel3d,
    Slide,
    Map,
    MobileMap,
    ReusableCarousel,
    HeroLinks,
    Posts,
    // VueConfetti,
    Map,
    MobileMap,
    Gallary
},
  created() {
    // this.$confetti.start();
    // this.autoplay()
  },
  watch: {},
  methods: {
    // autoplay() {
    //   let app = this;
    //   this.timer = setInterval(function () {
    //     app.launch();
    //   }, 8000);
    // },
    // launch() {
    //   this.$confetti.stop();
    // },
    changeRouter(page) {
      console.log("clicked");
      this.$router.push("/" + page);
    },
    imageLoaded() {
      console.log("force load");
      this.$refs.carousel.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.mobilemap {
  display: none;
}

@media (max-width: 800px) {
  .map {
    display: none;
  }
  .mobilemap {
    display: block;
    text-align: center;
  }
  .mobilemap > img {
    max-width: 300px;
  }
}
.details {
  color: #c5913f;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.375rem 0.75rem;
  transition: color 0.3s ease-in-out, box-shadow 0.5s ease-in-out;
  border: 1px solid #c5913f;
}

.details:hover {
  color: #fff;
  box-shadow: inset 200px 0 0 0 #c5913f;
}
@import "../styles/hero.css";
</style>
