<template>
  <carousel
    :items="1"
    :dots="true"
    :lazyLoad="true"
    :autoplay="true"
    :autoplayTimeout="2000"
  >
    <img
      class="imgs"
      src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
    />
    <img
      class="imgs"
      src="https://media.gettyimages.com/photos/shah-faisal-masjid-islamabad-pakistan-picture-id912853916?s=612x612"
    />
    <img
      class="imgs"
      src="https://www.whatsappimages.in/wp-content/uploads/2021/07/Top-HD-sad-quotes-for-whatsapp-status-in-hindi-Pics-Images-Download-Free.gif"
    />
  </carousel>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  data() {
    return {};
  },
  components: {
    carousel,
  },
};
</script>
<style scoped>
@import "../styles/hero.css";
.imgs {
  height: 765px;
}
.owl-item {
  width: 100% !important;
}

.owl-carousel.owl-loaded {
  display: flex;
}
</style>
