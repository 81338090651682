<template>
  <div>
    <div class="py-5 text-center">
      <h1 class="py-1 fw-bold">Gallery</h1>
      <p class="text-muted"></p>
    </div>

    <div class="pb-5">
      <b-container class="bv-example-row">
        <b-row>
          <b-col cols="4" style="padding: 5px">
            <img
              class="insta-images"
              src="../../../assets/instagram/277319631_1007366360216889_4382782381570846372_n.jpg"
              alt=""
            />
          </b-col>
          <b-col cols="4" style="padding: 5px">
            <img
              class="insta-images"
              src="../../../assets/instagram/283472204_409454774173879_1339546452065205619_n.jpg"
              alt=""
          /></b-col>
          <b-col cols="4" style="padding: 5px">
            <img
              class="insta-images"
              src="../../../assets/instagram/284603814_3312546768978210_1674776817158657960_n.jpg"
              alt=""
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" style="padding: 5px">
            <img
              class="insta-images"
              src="../../../assets/instagram/287976673_740064380757865_8731253818548693419_n.jpg"
              alt=""
            />
          </b-col>
          <b-col cols="4" style="padding: 5px">
            <img
              class="insta-images"
              src="../../../assets/instagram/295552303_746286863374223_8763598798601393020_n.jpg"
              alt=""
          /></b-col>
          <b-col cols="4" style="padding: 5px">
            <img
              class="insta-images"
              src="../../../assets/instagram/295779598_423849216435714_6773974148445637214_n.jpg"
              alt=""
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
