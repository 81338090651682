<template>
  <nav class="navbar navbar-fixed-top sticky navbar-expand-lg p-md-2">
    <div class="pattern-img p-relative left">
      <img
        src="../../assets/navpattern.png"
        alt=""
        class="pattern pattern-left"
      />
    </div>
    <div class="container">
      <router-link class="navbar-brand" :to="'/'">
        <img src="../../assets/logo.png" alt="" width="130" height="60" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <div class="mx-auto"></div>
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="'/'">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/airports#HRF'"
              >Airports</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/corporate'"
              >Corporate</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/commercial'"
              >Commercial</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/downloads'"
              >Downloads</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/contact-us'"
              >Contact Us</router-link
            >
          </li>

          <li class="nav-item">
            <b-dropdown
              id="dropdown-right"
              right
              text="About Us"
              variant="transparent"
              toggle-class="border-white"
            >
              <li class="nav-item">
                <router-link class="nav-link" :to="'/our-story'"
                  >Our Story</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="nav-link" :to="'/Philosophies'"
                  >Philosophies</router-link
                >
              </li>

              <li class="nav-item">
                <router-link class="nav-link" :to="'/Leadership'"
                  >Leadership</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="'/management-team'"
                  >Management Team</router-link
                >
              </li>
            </b-dropdown>
          </li>
        </ul>
      </div>
    </div>
    <div class="pattern-img p-relative right">
      <img
        src="../../assets/navpattern.png"
        alt=""
        class="pattern pattern-left"
      />
    </div>
  </nav>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      data: {},
    };
  },
  methods: {},
};
</script>
<style scoped>
.sticky {
  position: fixed;
  top: -2px;
  /* When the element reaches top: 10px, it becomes fixed. */
  z-index: 100;
}

img {
  background-size: cover;
  width: auto !important;
}
.app .navbar {
  /* background: transparent; */
  /* background-color: #00000093; */
  background-color: white;
  /* background-color: transparent; */
  /* border-bottom: 0.3px solid white; */
  /* position: absolute; */
  width: 100%;
}

.navbar {
  /* transition: background 0.5s linear; */
  transition: all 0.8s;
}

.navbar:hover {
  background: white;
}

.navbar:hover .navbar-nav .nav-link {
  color: black !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255) !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: black !important;
}

a.nav-link.router-link-exact-active.router-link-active {
  color: black !important;
  font-weight: bold;
  border-bottom: 1px solid white;
}
a.nav-link:hover {
  /* border-bottom: 1px black solid; */
  /* font-weight: bold; */
}

.container.show-nav .navbar-collapse {
  display: block !important;
}

.navbar-fixed-top:hover .navbar-toggler {
  filter: invert(1) brightness(0);
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-relative {
  position: relative;
}

.navbar-fixed-top {
  display: flex;
  justify-content: center;
}

.pattern-img.left {
  justify-content: flex-end;
}

@media (min-width: 1400px) {
  .navbar-fixed-top .container {
    min-width: 1320px !important;
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .navbar-fixed-top .container {
    min-width: 1140px !important;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .navbar-fixed-top .container {
    min-width: 960px !important;
  }
}
/* @media (max-width: 991px) and (min-width: 768px) {
  .navbar-fixed-top .container {
    min-width: 720px !important;
  }
}
@media (max-width: 767px) and (min-width: 576px) {
  .navbar-fixed-top .container {
    min-width: 540px !important;
  }
} */
@media (min-width: 1024px) {
  .navbar-fixed-top div {
    display: flex;
    flex: 1;
  }
}
@media (max-width: 1024px) {
  .pattern-img {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .services_parent .services {
    overflow: hidden;
  }
}

.pattern-img.p-relative,
.pattern {
  max-height: 69.99px !important;
  height: 69.99px;
}
</style>
