export default [
  {
    path: "/commercial",
    name: "Commercial",
    component: () => import("./pages/index"),
  },
  {
    path: "/corporate",
    name: "Corporate",
    component: () => import("./pages/corporate"),
  },
  {
    path: "/details",
    name: "Details",
    component: () => import("./pages/details"),
  },
  {
    path: "/cdetails",
    name: "CorporateDetails",
    component: () => import("./pages/cdetails"),
  },
]
