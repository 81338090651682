<template>
  <div class="container px-4" id="custom-cards">
    <h2 class="pb-2 border-bottom">Latest Updates</h2>

    <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
      <div v-for="item in posts" :key="item.id" class="col">
        <div
          id="show_bg_2"
          v-if="item.media[0]"
          class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
          :style="{
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0), rgba(197, 145, 63, 0.4)), url(' +
              item.media[0].original_url +
              ')',
            backgroundSize: 'cover',
          }"
        >
          <router-link
            :to="{ path: 'post-details', query: { id: item.id } }"
            tag="div"
          >
            <div
              routerLink="['posts']"
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <h2 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                {{ item.title }}
              </h2>
              <ul class="d-flex list-unstyled mt-auto">
                <li class="d-flex align-items-center details">
                  <small>{{ item.post_type.type }}</small>
                </li>
                <li class="d-flex align-items-center">
                  <svg class="bi me-2" width="1em" height="1em">
                    <use xlink:href="#calendar3"></use>
                  </svg>
                  <small>{{ item.created_at | formatDate }}</small>
                </li>
              </ul>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      posts: [],
      url: null,
    };
  },
  components: {},
  created() {
    this.getLatestPosts();
  },
  methods: {
    async getLatestPosts() {
      try {
        // Fetching data
        const response = await fetch(`${this.baseUrl}latestposts`);
        // Parsing data
        const data = await response.json();
        // Setting data
        this.posts = data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style></style>
