<template>
  <div class="slider tw-h-screen tw-w-screen tw-relative">
    <div v-if="data.length > 0" class="slides tw-h-screen tw-w-screen">
      <transition-group
        name="slide"
        mode="out-in"
        enter-class="slide-in"
        leave-class="slide-out"
        enter-active-class="animated slide-in-active"
        leave-active-class="animated slide-out-active"
      >
        <div
          v-for="(item, index) in data"
          :key="index"
          v-if="index == active"
          class="tw-h-screen tw-w-screen"
          :class="{ 'swiper-slide-active': index == active }"
        >
          <img
            :src="data[active].original_url"
            class="tw-h-screen tw-w-screen tw-object-cover image-container"
            blank="true"
          />
        </div>
      </transition-group>
    </div>
    <div class="layer"></div>
  </div>
</template>

<script>
// Importing things we need
import HeroLinks from "./HeroLinks.vue";

export default {
  components: {
    HeroLinks,
  },
  data() {
    return {
      active: 0,
      data: [],
    };
  },
  created() {
    this.getData();
    this.autoplay();
  },
  methods: {
    autoplay() {
      let app = this;
      this.timer = setInterval(function () {
        app.move();
      }, 8000);
    },
    move() {
      // Checking id current index greater than data length
      if (this.active < this.data.length - 1) this.active++;
      // if is not greater than data length than setting its value to 0
      else this.active = 0;
    },
    async getData() {
      // Try & catch code block
      try {
        // Fetching data
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}heros`);
        // Parsing data
        const data = await response.json();
        // Setting data
        this.data = data[0].media;
        // Changing load value
        this.load = false;
      } catch (e) {
        // Catching error
        this.errored = true;
        this.load = false;
      }
    },
    jump(index) {
      this.active = index;
    },
    play: function () {
      let app = this;
      this.timer = setInterval(function () {
        move(1);
      }, 2000);
    },
  },
  components: { HeroLinks },
};
</script>

<style>
.slider {
  overflow: hidden;
}

.layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 26, 61, 0.3);
}

.prev,
.next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid #221e21;
  color: #221e21;
  border-radius: 50%;
  margin-top: -25px;
  margin-left: 25px;
  cursor: pointer;
  line-height: 48px;
  text-align: center;
  text-indent: -2px;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.prev:hover,
.next:hover {
  background: #221e21;
  color: #fff;
  transform: scale(1.2);
}
.prev:active,
.next:active {
  transform: translate(0, 3px) scale(1.2);
}

.next {
  right: 0;
  margin-left: auto;
  margin-right: 25px;
  text-indent: 2px;
}

.dots {
  position: fixed;
  /* display: block; */
  width: 100%;
  text-align: center;
  bottom: 20px;
}
.dots li {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #221e21;
  opacity: 0.2;
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out,
    width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.dots li.active {
  width: 22px;
  opacity: 1;
}

.slides {
  font-size: 40px;

  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
@media (min-width: 600px) {
  .slides {
    font-size: 80px;
  }
}
@media (min-width: 900px) {
  .slides {
    font-size: 140px;
  }
}
.slides .animated {
  transition: all 900ms;
  position: absolute;
  /* transform: translate(-50%, 0%); */
}
.slides .slide-in {
  opacity: 0;
  /* transform: translate(-40%, 0%); */
}
.slides .slide-in-active {
  transition-delay: 150ms;
}
.slides .slide-out {
  opacity: 1;
}
.slides .slide-out-active {
  opacity: 0;
  /* transform: translate(-60%, 0%); */
}

.buttons {
  position: absolute;
  top: 10px;
  left: 10px;
}

button {
  padding: 10px;
  outline: none;
  border: none;
  -webkit-appearance: none;
  background: #221e21;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
button:disabled {
  opacity: 0.2;
  cursor: no-drop;
}
.swiper-slide-active > .image-container {
  animation: photozoom ease-in-out;
  animation-duration: 8000ms;
}

@keyframes photozoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
</style>
